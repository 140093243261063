<template>
  <div
      class="aspect-100/140 md:aspect-100/145 lg:aspect-100/160 xl:aspect-100/147 w-full relative p-4 xl:p-6 pb-3 rounded-16px overflow-hidden flex flex-col group border border-bw-10 bg-box-card-product hover:active duration-300 box-main-product max-w-md mx-auto hover:border-primary duration-300"
      :key="renderData?.id"
  >
    <nuxt-link :to="renderData?.url" class="mb-3 lg:mb-4.5">
      <div class="aspect-92/53">
        <img
            :src="getThumbnail(renderData?.thumbnail?.id, 800, true)"
            :alt="renderData?.thumbnail?.description || renderData?.thumbnail?.title"
            :class="renderData?.thumbnail ? 'group-hover:block' : 'group-hover:hidden'"
            class="w-full h-full object-cover rounded-2.5"
        />
        <img
            :src="useDirectusFiles().getThumbnail('083b0e35-8c82-4afe-9f4f-3d408503c16f', { width: 800 })"
            :alt="renderData?.title"
            :class="!renderData?.thumbnail?.id ? 'group-hover:block' : ''"
            class="w-full h-full object-cover rounded-2.5 hidden"
        />
      </div>
    </nuxt-link>
    <div class="px-2 flex flex-col h-full">
      <div class="flex justify-between items-center lg:mb-4">
        <a :href="renderData?.carrier?.url" target="_blank">
          <img
              :src="getThumbnail(renderData?.carrier?.thumbnail?.id, 100)"
              :alt="renderData?.carrier?.thumbnail?.description || renderData?.carrier?.thumbnail?.title"
              class="w-12 h-12 lg:w-10 lg:h-10 object-contain logo-customer"
          />
        </a>

        <button class="flex" @click="handleCopyLink()">
          <span class="w-6 h-6 lg:w-4.5 lg:h-4.5 i-custom-share text-primary group-hover:text-white"></span>
        </button>

        <Teleport v-if="isLoaded" to="body">
          <GlobalPopupCopySuccess v-model:isOpen="copyLink"/>
        </Teleport>
      </div>

      <nuxt-link :to="renderData?.url" class="flex-1 flex flex-col">
        <p
            class="uppercase text-10px xl:text-13px text-primary font-semibold leading-6 md:mb-2 group-hover:text-white/80"
        >
          {{ renderData?.category.title }}
        </p>
        <!-- <GlobalSkeleton :loading="loading" class="w-full h-7 mb-4" /> -->

        <h3
            class="mt-1 lg:mt-0 text-xl xl:text-26px leading-6 xl:leading-8 text-black font-[550] mb-2.5 group-hover:text-white line-clamp-2"
        >
          {{ renderData?.title }}
        </h3>
        <p
            class="text-bw-14/90 lg:leading-5 xl:leading-6 text-xs xl:text-base mb-5 group-hover:text-white/85 line-clamp-3"
        >
          {{ renderData?.intro }}
        </p>

        <button
            class="px-8 py-3 rounded-23px uppercase duration-400 font-medium xl:font-semibold flex justify-center items-center bg-primary group-hover:bg-white text-bw-01 group-hover:text-primary mt-auto text-xs xl:text-sm mb-3 lg:mb-2 xl:mb-0"
        >
          <span>{{ $t('LEARN_MORE') }}</span>
          <span class="text-white w-4.5 h-4.5 i-custom-chevron-right group-hover:text-primary"></span>
        </button>
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import {getThumbnail} from '../../composables/useHandleImage'

const props = defineProps({
  item: {
    type: Object as any
  },
  loading: {
    type: Boolean
  }
})
const renderData = computed(() => props.item)

const copyToClipboard = (url: any) => {
  var sampleTextarea = document.createElement('textarea')
  document.body.appendChild(sampleTextarea)
  sampleTextarea.value = url //save main text in it
  sampleTextarea.select() //select textarea contenrs
  document.execCommand('copy')
  document.body.removeChild(sampleTextarea)
}

const href = ref()
const copyLink = ref(false)
const handleCopyLink = () => {
  copyLink.value = true
  copyToClipboard(href.value)
  setTimeout(() => {
    copyLink.value = false
  }, 5000)
}
const isLoaded = ref(false)

onMounted(() => {
  const rootURL = window.location.origin
  href.value = rootURL + renderData.value?.url
  isLoaded.value = true
})
</script>

